@import '~antd/dist/antd.css';

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.3);
    margin: 16px;
}

.site-layout .site-layout-background {
    background: #fff;
    height: auto;
}

.ant-image-img {
    display: inline !important;
}

.ant-btn .anticon {
    /*margin-right: 5px;*/
}

.ant-spin-container {
    height: 100% !important;
    display: block !important;
    flex-flow: wrap !important;
}

.ant-table-row-expand-icon {
    display: none !important;
}
