.ant-table-wrapper {
    height: 100% !important;
}
.ant-spin-nested-loading {
    height: 100% !important;
}
.ant-spin-container {
    height: 100% !important;
    display: flex !important;
    flex-flow: column nowrap !important;
}
.ant-table {
    flex: auto !important;
    overflow: hidden !important;
}
.ant-table-container {
    height: 100% !important;
    display: flex !important;
    flex-flow: column nowrap !important;
}
.ant-table-header {
    flex: none !important;
}

.ant-table-body {
    flex: auto !important;
    overflow: scroll !important;
}
.ant-table-pagination {
    flex: none !important;
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.3);
    margin: 16px;
}

.site-layout .site-layout-background {
    background: #fff;
    height: auto;
}

.ant-image-img {
    display: inline !important;
}

.ant-btn .anticon {
    /*margin-right: 5px;*/
}

.ant-spin-container {
    height: 100% !important;
    display: block !important;
    flex-flow: wrap !important;
}

.ant-table-row-expand-icon {
    display: none !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    height: 80% !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.LOCAL {
    background-color: #45d52c;
    color: white;
    padding: 8px;
    /* font-weight: bold; */
    border-radius: 20px;
    margin-right: 20px;
}

.DEV {
    background-color: #2c73d5;
    color: white;
    padding: 12px;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 20px;
}

.PROD {
    background-color: #d5572c;
    color: white;
    padding: 12px;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 20px;
}

.ActionButtons {
    margin-left: 9px;
    margin-right: 9px;
    font-size: large;
}

.QrButton {
    margin-right: 7.5px;
    font-size: large;
}

/* table */
.ant-table {
    font-size: 14px;
}

/* row data */
.ant-table-tbody > tr > td {
    height: 6px;
    padding: 6px;
    background-color: white;
}

.ant-table-thead > tr > th {
    height: 5px;
    padding: 4px;
}

.ant-tag {
    font-weight: bold;
    font-size: 15px;
    border-width: 3px;
    border-radius: 4px;
}

.ant-table-column-sorters {
    padding: 5px 5px;
}

.activeCard {
    border-color: blue;
    border-width: 1.5px;
}

.clickRowStyl {
    font-weight: bold;
    -webkit-filter: contrast(90%) !important;
            filter: contrast(90%) !important;
}

.ant-table-tbody > .clickRowStyl:hover > td {
    font-weight: bold;
    -webkit-filter: contrast(90%) !important;
            filter: contrast(90%) !important;
}

.ant-table-tbody > tr {
    cursor: pointer;
}

.ant-table-pagination.ant-pagination {
    margin: 10px 0 0 0;
}

