body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    height: 80% !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.LOCAL {
    background-color: #45d52c;
    color: white;
    padding: 8px;
    /* font-weight: bold; */
    border-radius: 20px;
    margin-right: 20px;
}

.DEV {
    background-color: #2c73d5;
    color: white;
    padding: 12px;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 20px;
}

.PROD {
    background-color: #d5572c;
    color: white;
    padding: 12px;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 20px;
}

.ActionButtons {
    margin-left: 9px;
    margin-right: 9px;
    font-size: large;
}

.QrButton {
    margin-right: 7.5px;
    font-size: large;
}

/* table */
.ant-table {
    font-size: 14px;
}

/* row data */
.ant-table-tbody > tr > td {
    height: 6px;
    padding: 6px;
    background-color: white;
}

.ant-table-thead > tr > th {
    height: 5px;
    padding: 4px;
}

.ant-tag {
    font-weight: bold;
    font-size: 15px;
    border-width: 3px;
    border-radius: 4px;
}

.ant-table-column-sorters {
    padding: 5px 5px;
}

.activeCard {
    border-color: blue;
    border-width: 1.5px;
}

.clickRowStyl {
    font-weight: bold;
    filter: contrast(90%) !important;
}

.ant-table-tbody > .clickRowStyl:hover > td {
    font-weight: bold;
    filter: contrast(90%) !important;
}

.ant-table-tbody > tr {
    cursor: pointer;
}

.ant-table-pagination.ant-pagination {
    margin: 10px 0 0 0;
}
